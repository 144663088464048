import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Space, Typography } from '@pitchero/react-ui';
import { Trans } from '../../lib/i18n';
import { fetchNetworkPanelData, submitBecomeClubMember } from '../../store/membership/actions';
import {
  getPrimaryMemberId,
  hasAppliedForMembership,
  hasLoadedNetworkPanel,
  isLoadingNetworkPanel,
  isSubmittingApplication,
} from '../../store/membership/selectors';
import TokenRedirect from '../token-redirect-link/route';
import LoadingBar from '../loading-bar';

const ApplyForMembershipButton = ({
  applyForMembership,
  clubId,
  hasLoaded,
  isLoading,
  isSubmitting,
  loadNetworkPanelData,
  memberId,
  pendingApplication,
}) => {
  useEffect(() => {
    if (!isLoading && !hasLoaded) {
      loadNetworkPanelData(clubId);
    }
  }, []);

  if (isLoading) {
    return (
      <>
        <LoadingBar isLoading />
        <Typography preset="body--small" color="dustygrey">
          <Trans i18nKey="common:checking_access">Checking access</Trans>
        </Typography>
      </>
    );
  }

  if (pendingApplication) {
    return (
      <>
        <Space right="xsmall">
          <Typography preset="body--small" color="dustygrey">
            <Trans i18nKey="common:pending_membership_application">
              Your membership application is pending.
            </Trans>
          </Typography>
        </Space>
        <TokenRedirect route="account.membership.roles" params={{ memberId }}>
          <a>
            <Typography preset="body--small" color="primary">
              <Trans i18nKey="common:manage_roles">Manage your roles</Trans>
            </Typography>
          </a>
        </TokenRedirect>
      </>
    );
  }

  return (
    <>
      <LoadingBar isLoading={isSubmitting} />
      <Button
        theme="primary"
        onClick={() => applyForMembership(clubId, memberId)}
        disabled={isSubmitting}
      >
        <Trans i18nKey="common:apply_for_membership_label">Apply for membership</Trans>
      </Button>
    </>
  );
};

const mapStateToProps = (state, { clubId }) => ({
  hasLoaded: hasLoadedNetworkPanel(state),
  isLoading: isLoadingNetworkPanel(state),
  isSubmitting: isSubmittingApplication(state),
  memberId: getPrimaryMemberId(state, clubId),
  pendingApplication: hasAppliedForMembership(state, clubId),
});

ApplyForMembershipButton.defaultProps = {
  memberId: null,
};

ApplyForMembershipButton.propTypes = {
  applyForMembership: PropTypes.func.isRequired,
  clubId: PropTypes.number.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  loadNetworkPanelData: PropTypes.func.isRequired,
  memberId: PropTypes.number,
  pendingApplication: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, {
  loadNetworkPanelData: fetchNetworkPanelData,
  applyForMembership: submitBecomeClubMember,
})(ApplyForMembershipButton);
